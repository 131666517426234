<template>
    <b-container fluid>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('elearning_tim.pre_post_evaluation')}}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="6" sm="12">
                <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="fiscal_year_id"
                >
                  <template v-slot:label>
                    {{$t('globalTrans.fiscal_year')}}
                  </template>
                  <!-- <b-form-select
                    plain
                    v-model="search.fiscal_year_id"
                    :options="fiscalYearList"
                    id="fiscal_year_id"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select> -->
                  <v-select name="fiscal_year_id"
                    v-model="search.fiscal_year_id"
                    label="text"
                    :reduce="item => item.value"
                    :options= fiscalYearList
                    :placeholder="$t('globalTrans.select')"
                    :filter-by="myFilter"
                  />
                </b-form-group>
                </b-col>
                <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1" >
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="org_id"
                  >
                    <template v-slot:label>
                      {{$t('elearning_config.organization')}}
                    </template>
                    <!-- <b-form-select
                      plain
                      v-model="search.org_id"
                      :options="orgList"
                      id="org_id"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select> -->
                  <v-select name="org_id"
                    v-model="search.org_id"
                    label="text"
                    :reduce="item => item.value"
                    :options= orgList
                    :placeholder="$t('globalTrans.select')"
                    :filter-by="myFilter"
                  />
                  </b-form-group>
                </b-col>
                <b-col lg="6" sm="12">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="training_type_id"
                  >
                    <template v-slot:label>
                      {{$t('elearning_config.training_type')}}
                    </template>
                    <!-- <b-form-select
                      plain
                      v-model="search.training_type_id"
                      :options="trainingTypeList"
                      id="training_type_id"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select> -->
                  <v-select name="training_type_id"
                    v-model="search.training_type_id"
                    label="text"
                    :reduce="item => item.value"
                    :options= trainingTypeList
                    :placeholder="$t('globalTrans.select')"
                    :filter-by="myFilter"
                  />
                  </b-form-group>
                </b-col>
                <b-col lg="6" sm="12">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="training_category_id"
                  >
                    <template v-slot:label>
                      {{$t('elearning_config.training_category')}}
                    </template>
                    <!-- <b-form-select
                      plain
                      v-model="search.training_category_id"
                      :options="trainingCategoryList"
                      id="training_category_id"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select> -->
                  <v-select name="training_category_id"
                    v-model="search.training_category_id"
                    label="text"
                    :reduce="item => item.value"
                    :options= trainingCategoryList
                    :placeholder="$t('globalTrans.select')"
                    :filter-by="myFilter"
                  />
                  </b-form-group>
                </b-col>
                <b-col lg="6" sm="12">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="training_title_id"
                  >
                    <template v-slot:label>
                      {{$t('elearning_config.training_title')}}
                    </template>
                    <!-- <b-form-select
                      plain
                      v-model="search.training_title_id"
                      :options="trainingTitleList"
                      id="training_title_id"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select> -->
                  <v-select name="training_title_id"
                    v-model="search.training_title_id"
                    label="text"
                    :reduce="item => item.value"
                    :options= trainingTitleList
                    :placeholder="$t('globalTrans.select')"
                    :filter-by="myFilter"
                  />
                  </b-form-group>
                </b-col>
                <b-col lg="6" sm="12">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="batch_no"
                  >
                  <template v-slot:label>
                    {{$t('elearning_iabm.batch_no')}}
                  </template>
                  <b-form-select
                    plain
                    v-model="search.batch_no"
                    :options="batchList"
                    id="batch_no"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col lg="6" sm="12">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="circular_memo_no"
                  >
                  <template v-slot:label>
                    {{$t('elearning_iabm.circular_memo_no')}}
                  </template>
                  <b-form-select
                    plain
                    v-model="search.circular_memo_no"
                    :options="circularList"
                    id="circular_memo_no"
                    >
                    <template v-slot:first>
                        <b-form-select-option value=''>{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col lg="6" sm="12">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="exam_date"
                  >
                    <template v-slot:label>
                      {{ $t('elearning_tim.evaluation_date') }}
                    </template>
                    <b-form-input
                        class="fromDate"
                        id="exam_date"
                        v-model="search.exam_date"
                        :placeholder="$t('globalTrans.select_date')"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="12" sm="12" class="text-right">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('elearning_tim.pre_post_evaluation')}} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary btn_add_new" >
                          <router-link :to="{ path: '/training-e-learning-service/tim/evaluation-form'}" size="sm">
                            <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                          </router-link>
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                      <template v-slot:cell(index)="data">
                                      {{ $n(data.index + pagination.slOffset) }}
                                      </template>
                                      <template v-slot:cell(training_title)="data">
                                        <span class="capitalize">{{ data.item.training_title }}</span>
                                      </template>
                                      <template v-slot:cell(exam_date)="data">
                                        <span class="capitalize">{{ data.item.exam_date | dateFormat }}</span>
                                      </template>
                                      <template v-slot:cell(exam_type)="data">
                                        <span class="">{{ examTypeData(data.item.exam_type) }}</span>
                                      </template>
                                      <template v-slot:cell(status)="data">
                                        <span class="badge badge-warning" v-if="data.item.status == 1">{{$t('elearning_config.pending')}}</span>
                                        <span class="badge badge-success" v-else>{{$t('globalTrans.published')}}</span>
                                      </template>
                                      <template v-slot:cell(action)="data">
                                        <a href="javascript:" class="btn_table_action table_action_view mr-1" v-if="data.item.course_evaluation">
                                          <!-- <router-link :to="{ path: '/training-e-learning-service/tim/evaluation-user', query: { id: data.item.id }}" size="sm"><i class="fa fa-user"></i> </router-link> -->
                                          <router-link :to="`/training-e-learning-service/tim/evaluation-user?id=${data.item.id}&&circularMemoNo=${data.item.circular_memo_no}`" size="sm"><i class="fa fa-user"></i> </router-link>
                                        </a>
                                        <a href="javascript:" class="btn_table_action table_action_view mr-1" v-b-modal.modal-4 @click="edit(data.item)">
                                          <i class="fas fa-eye"></i>
                                        </a>
                                        <a href="javascript:" class="btn_table_action table_action_edit mr-1"> <router-link :to="{ path: '/training-e-learning-service/tim/evaluation-form', query: { id: data.item.id }}" size="sm"><i class="ri-ball-pen-fill m-0"></i> </router-link></a>
                                        <a v-if="data.item.status === 1" size="sm" @click="publishStatus(data.item)" class="btn moc-action-btn moc-publish-btn btn-secondary" :title="$t('globalTrans.publish')">{{ $t('globalTrans.publish') }}</a>
                                      </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="$t('elearning_tim.pre_post_evaluation')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <template #modal-title class="flex-grow-1">
            {{ $t('elearning_tim.pre_post_evaluation') }}
            <b-button variant="primary" @click="pdfExport" class="mr-2 float-right">
              <i class="fas fa-print"></i> {{  $t('globalTrans.print') + $t('elearning_tim.with_ans') }}
            </b-button>
            <!-- <b-button variant="primary" @click="pdfDownload" class="mr-2 float-right" style="background-color: aliceblue; color: black;"> -->
            <b-button variant="primary" @click="pdfDownload" class="mr-2 float-right">
              <i class="fas fa-print"></i> {{  $t('globalTrans.print') + $t('elearning_tim.without_ans') }}
            </b-button>
          </template>
          <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { evaluationList, evaluationPublish, circularList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'
import Details from './Details'
import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Details
    },
    data () {
      return {
        search: {
          exam_date: '',
          circular_memo_no: '',
          batch_no: 0,
          coordinator_id: 0,
          org_id: null,
          training_type_id: null,
          training_category_id: null,
          training_title_id: null,
          fiscal_year_id: null
        },
        circularLoading: false,
        item: [],
        trainingTypeList: [],
        trainingCategoryList: [],
        circularList: [],
        allBatchListData: [],
        batchList: [],
        trainingTitleList: [],
        myFilter: (option, text, search) => {
                const temp = search.toLowerCase()
                return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                option.text_bn.toLowerCase().indexOf(temp) > -1
            }
      }
    },
    computed: {
      examTypeList: function () {
        return [
          { text: this.currentLocale === 'en' ? 'Pre-Evaluation' : 'প্রি-ইভালুয়েশন', value: 1, text_en: 'Pre-Evaluation', text_bn: 'প্রি-ইভালুয়েশন' },
          { text: this.currentLocale === 'en' ? 'Post-Evaluation' : 'পোস্ট-ইভালুয়েশন', value: 2, text_en: 'Post-Evaluation', text_bn: 'পোস্ট-ইভালুয়েশন' }
        ]
      },
      allBatchList () {
        const batchList = [
          { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
          { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
          { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
          { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
          { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
          { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
          { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
          { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
          { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
          { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
        ]
        return batchList
      },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.registration') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.registration') + ' ' + this.$t('elearning_config.update')
      },
      columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('elearning_tim.evaluation_type'), class: 'text-center' },
          { label: this.$t('elearning_tim.evaluation_date'), class: 'text-center' },
          { label: this.$t('elearning_config.organization'), class: 'text-center' },
          { label: this.$t('elearning_config.training_title'), class: 'text-center' },
          { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
          keys = [
          { key: 'index' },
          { key: 'exam_type' },
          { key: 'exam_date' },
          { key: 'org_bn' },
          { key: 'training_title_bn' },
          { key: 'circular_memo_no' },
          { key: 'status' },
          { key: 'action' }
          ]
        } else {
          keys = [
          { key: 'index' },
          { key: 'exam_type' },
          { key: 'exam_date' },
          { key: 'org' },
          { key: 'training_title' },
          { key: 'circular_memo_no' },
          { key: 'status' },
          { key: 'action' }
          ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
      }
    },
    async created () {
      this.search = Object.assign({}, this.search, {
        // org_id: this.$store.state.dataFilters.orgId,
        fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
      })
      if (this.$store.state.Auth.activeRoleId !== 1) {
        this.search.org_id = this.$store.state.Auth.authUser.org_id
        if (this.$store.state.Auth.authUser.is_org_admin !== 1 && parseInt(this.$store.state.Auth.authUser.org_id) === 12) {
          this.search.coordinator_id = this.$store.state.Auth.authUser.user_id
        }
      }
      this.loadData()
    },
    watch: {
      'search.fiscal_year_id': function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.search.circular_memo_no = ''
          this.search.batch_no = 0
          this.getCircularList()
        }
      },
     'search.org_id': function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.search.training_type_id = null
          this.trainingTypeList = this.getTypeList(newValue)
        } else {
          this.trainingTypeList = []
        }
      },
      'search.training_type_id': function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.search.training_category_id = null
          this.trainingCategoryList = this.getCategoryList(newValue)
        } else {
          this.trainingCategoryList = []
        }
      },
      'search.training_category_id': function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.search.training_title_id = null
          this.trainingTitleList = this.getTrainingTitleList(newValue)
        } else {
          this.trainingTitleList = []
        }
      },
      'search.training_title_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.search.circular_memo_no = ''
          this.search.batch_no = 0
          this.getCircularList()
        }
      },
      'search.circular_memo_no': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          const allBatchListData = this.allBatchListData.find(allBatchListData => String(allBatchListData.circular_memo_no) === String(newVal))
          if (typeof allBatchListData !== 'undefined') {
            this.search.batch_no = allBatchListData.batch_no
          }
        }
      },
      'search.batch_no': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          const allBatchListData = this.allBatchListData.find(allBatchListData => parseInt(allBatchListData.batch_no) === parseInt(newVal))
          if (typeof allBatchListData !== 'undefined') {
            this.search.circular_memo_no = allBatchListData.circular_memo_no
          }
        }
      }
    },
    mounted () {
      core.index()
      flatpickr('.fromDate', {})
    },
    methods: {
      getTypeList (orgId) {
        if (this.search.org_id) {
          const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
          if (orgId) {
            return type.filter(item => item.org_id === parseInt(orgId))
          }
          return type
        }
      },
      getCategoryList (typeId) {
        if (this.search.training_type_id) {
          const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
          if (typeId) {
            return trainingCategoryList.filter(item => item.training_type_id === typeId)
          }
          return trainingCategoryList
        }
      },
      getTrainingTitleList (categoryId) {
        if (this.search.training_category_id) {
          const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
          if (categoryId) {
            return trainingTitleList.filter(item => item.training_category_id === categoryId)
          }
          return trainingTitleList
        }
      },
      batchInfo () {
        const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.search.training_title_id))
        const batchList = []
        batchData.map((obj, index) => {
          if (obj.batch_no) {
            const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
            if (typeof isThere !== 'undefined') {
              batchList.push(isThere)
            }
          }
        })
        this.batchList = batchList
        if (this.batchList.length === 1) {
          this.search.batch_no = 1
        }
      },
      circluarList () {
        const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.search.training_title_id))
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          }
        })
        this.circularList = tmpList
      },
      async getCircularList () {
        if (this.search.fiscal_year_id && this.search.training_title_id) {
          this.circularLoading = true
          const serchData = {
            fiscal_year_id: this.search.fiscal_year_id,
            training_type_id: this.search.training_type_id,
            training_category_id: this.search.training_category_id,
            training_title_id: this.search.training_title_id,
            coordinator_id: this.search.coordinator_id
          }
          const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
          if (!result.success) {
          } else {
            this.allBatchListData = result.batchList
            this.batchInfo()
            this.circluarList()
          }
          this.circularLoading = false
        }
      },
      async searchData () {
        this.loadData()
      },
      loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, evaluationList, params).then(response => {
            if (response.success) {
                this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                this.paginationData(response.data)
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const customItem = {}
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          if (typeof orgObj !== 'undefined') {
            customItem.org = orgObj.text_en
            customItem.org_bn = orgObj.text_bn
          } else {
            customItem.org = ''
            customItem.org_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          if (typeof OfficeTypeListObj !== 'undefined') {
            customItem.office_type = OfficeTypeListObj.text_en
            customItem.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            customItem.office_type = ''
            customItem.office_type_bn = ''
          }
          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          if (typeof officeObj !== 'undefined') {
            customItem.office = officeObj.text_en
            customItem.office_bn = officeObj.text_bn
          } else {
            customItem.office = ''
            customItem.office_bn = ''
          }
          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          if (typeof fiscalYearObj !== 'undefined') {
            customItem.fiscal_year = fiscalYearObj.text_en
            customItem.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            customItem.fiscal_year = ''
            customItem.fiscal_year_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          if (typeof trainingTypeObj !== 'undefined') {
            customItem.training_type = trainingTypeObj.text_en
            customItem.training_type_bn = trainingTypeObj.text_bn
          } else {
            customItem.training_type = ''
            customItem.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          if (typeof trainingCategoryObj !== 'undefined') {
            customItem.training_category = trainingCategoryObj.text_en
            customItem.training_category_bn = trainingCategoryObj.text_bn
          } else {
            customItem.training_category = ''
            customItem.training_category_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          if (typeof trainingTitleObj !== 'undefined') {
            customItem.training_title = trainingTitleObj.text_en
            customItem.training_title_bn = trainingTitleObj.text_bn
          } else {
            customItem.training_title = ''
            customItem.training_title_bn = ''
          }
          const courseObj = this.$store.state.TrainingElearning.commonObj.courseList.find(doc => doc.value === parseInt(item.course_id))
          if (typeof courseObj !== 'undefined') {
            customItem.course_name = courseObj.text_en
            customItem.course_name_bn = courseObj.text_bn
          } else {
            customItem.course_name = ''
            customItem.course_name_bn = ''
          }

          return Object.assign({}, item, customItem)
        })
        return listData
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      },
      pdfDownload () {
        this.$refs.details.pdfDownload()
      },
      publishDataStatus (baseUrl, uri, item, destination = null, dropdownName = null) {
        Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
          if (response.success) {
            if (destination !== null && dropdownName !== null) {
              Store.dispatch('toggleDropdownItemStatus', { itemId: item.id, destination: destination, dropdownName: dropdownName })
            }
            window.vm.$toast.success({
              title: this.$t('globalTrans.success'),
              message: this.$t('elearning_config.update_msg'),
              color: '#D6E09B'
            })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
      },
      publishStatus (item) {
        window.vm.$swal({
          title: window.vm.$t('elearning_iabm.publish_status'),
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.publishDataStatus(trainingElearningServiceBaseUrl, evaluationPublish, item)
          }
        })
      },
      examTypeData (id) {
        const batchData = this.examTypeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return batchData !== undefined ? batchData.text_bn : ''
        } else {
            return batchData !== undefined ? batchData.text_en : ''
        }
      }
    }
}
</script>

<style>
.modal-title {
  flex-grow: 1 !important;
}
</style>
